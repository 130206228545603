<template>
  <div ref="narrative" id="narrative">
    <div class="canvas-spacer lg"></div>
    <div
      class="top-container d-flex text-left p-2 justify-content-between flex-row align-items-center"
    >
      <div>
        <div class="d-flex flex-row align-items-center">
          <h1 class="page-title">Narrative Creator</h1>
        </div>
        <div class="description" v-html="landingDescription"></div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between p-2 mt-4"
      v-if="narrativeLength > 0"
    >
      <div class="narrative-form">
        <div class="row mb-4">
          <div class="col-12">
            <b-input-group class="mt-4">
              <b-form-input
                id="input-narrative-title"
                placeholder="Enter narrative title"
                v-model="narrativeTitle"
                type="text"
                key="narrative-title"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  id="resetNarrative"
                  @click="resetNarrative"
                  variant="outline-warning"
                  class=""
                  >reset</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <b-form-textarea
          id="textarea"
          v-model="narrativeDescription"
          placeholder="Enter Description"
          rows="4"
          key="narrative-description"
          no-resize
        ></b-form-textarea>
        <span
          class="invalid-feedback"
          :style="{
            display: this.narrativeIDCheck === true ? 'block' : 'none',
          }"
          >Narrative Title is not unique</span
        >
        <b-input-group class="mt-4 font-mute">
          <b-input-group-prepend>
            <b-button
              id="createNarrative"
              @click="createNarrative"
              variant="outline-light"
              >create narrative</b-button
            >
          </b-input-group-prepend>
          <b-form-input
            placeholder="create scout url"
            id="narrativeURL"
            v-model="narrativeURL"
            style="user-select: none"
            type="url"
            readonly
          >
          </b-form-input>
          <b-input-group-append>
            <b-button variant="outline-info" @click="openWindow">
              <linkIcon height="15" />
            </b-button>
            <b-button variant="info" @click="copyToClipboard">
              <clipboard height="15" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="narrative-card d-flex">
        <img
          :src="currentObject.image"
          alt="narrative image"
          width="400"
          height="auto"
        />
        <div class="card-metric-panel d-flex flex-column ml-4 flex-grow-1">
          <div
            id="metric-inputs-container"
            class="d-flex align-items-center flex-wrap justify-content-between mb-2"
          >
            <div
              class="input-label d-flex align-items-center m-1"
              v-for="(item, index) in inputArray"
              :key="index"
            >
              <div class="sublabel">{{ item.label }}:</div>
              <div class="label ml-2">
                {{ item.value | getInputLabel(item.key, item.marks) }}
              </div>
            </div>
          </div>
          <div
            id="metric-outputs-container"
            class="d-flex flex-column align-items-stretch justify-content-between flex-grow-1 mt-2"
          >
            <div
              class="metric-panel d-flex justify-content-between"
              v-for="metric in metricCard"
              :key="'card-' + metric.label"
            >
              <div class="metric-label text-left">
                <div class="label">{{ metric.label }}</div>
                <div class="units-text">
                  {{ metric.prettyValue }} {{ metric.units }}
                </div>
              </div>
              <div class="star-parent d-flex align-items-start">
                <star
                  v-for="(star, index) in metric.starRatingArray"
                  :key="index"
                  :typeOfStar="star"
                  :aboveBenchmark="metric.benchmark"
                  :width="20"
                  :height="20"
                  :starColor="null"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex text-left pl-2 pr-2 mt-4 justify-content-between flex-row align-items-center"
      v-if="narrativeLength > 0"
    >
      <div>
        <div class="d-flex flex-row align-items-center">
          <h4 class="page-title">Timeline</h4>
          <div class="model-counter">
            <span class="ml-2">[ {{ narrativeLength }} ]</span>
          </div>
        </div>
        <div class="description">click a model to edit</div>
      </div>
    </div>

    <section>
      <vue-horizontal-list :items="exploreObject" :options="options">
        <template v-slot:default="{ item }">
          <div
            class="item text-left"
            :class="{ active: currentIndex === item.index }"
            @click="updateCurrentModel(item.index)"
          >
            <div
              v-if="currentIndex === item.index"
              id="exitOpenButton"
              class="exit-button-layout right m-1"
              @click.stop="removeItem(item.index)"
            >
              x
            </div>
            <img :src="item.image" alt="image" width="250" height="auto" />
            <div class="d-flex align-items-center mt-4">
              <h5 v-if="currentIndex !== item.index" class="mt-2 mb-2">
                {{ item.title }}
              </h5>
              <b-form-input
                v-if="currentIndex === item.index"
                id="input-title"
                placeholder="Enter Title"
                v-model="item.title"
                type="text"
                key="narrative-title"
              ></b-form-input>
            </div>

            <p v-if="currentIndex !== item.index">{{ item.description }}</p>
            <b-form-textarea
              v-if="currentIndex === item.index"
              id="textarea"
              v-model="item.description"
              placeholder="Enter Description"
              rows="2"
              key="narrative-description"
              no-resize
            ></b-form-textarea>
            <div
              v-if="currentIndex === item.index"
              class="d-flex-content-center"
            >
              <div
                class="w-25 mr-4 d-flex justify-content-end font-xlg adjust-button"
                @click.stop="increment(false, item.index)"
              >
                &#x2039;
              </div>
              <div class="d-flex-content-center">
                <span>adjust order</span>
              </div>
              <div
                class="w-25 ml-4 font-xlg adjust-button"
                @click.stop="increment(true, item.index)"
              >
                &#x203A;
              </div>
            </div>
          </div>
        </template>
      </vue-horizontal-list>
    </section>
  </div>
</template>

<script>
import narrativeObject from '@/assets/js/narrative.js'

import VueHorizontalList from 'vue-horizontal-list'
import { mapGetters } from 'vuex'
// import helper functions
import { getStarRating } from '@/assets/js/helper.js'
// star template
import star from '@/components/Utility/star.vue'
import clipboard from '@/assets/icons/clipboard-regular.svg'
import linkIcon from '@/assets/icons/link-solid.svg'

export default {
  name: 'narrativeMode',
  components: {
    VueHorizontalList,
    star,
    clipboard,
    linkIcon
  },
  data () {
    return {
      title: '',
      description: '',
      exploreObject: narrativeObject['exploreMode'],
      narrativeURL: '',
      options: {
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 2 },
          { start: 768, end: 992, size: 3 },
          { size: 4 }
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,

          // Because: #app {padding: 80px 24px;}
          padding: 24
        }
      },
      newIndex: 0,
      currentIndex: 0,
      narrativeTitle: '',
      narrativeDescription: '',
      narrativeIDCheck: false
    }
  },
  filters: {
    getInputLabel (i, key, marks) {
      return marks[i].label
    }
  },
  created () {
    if (this.narrativeLength > 0) {
      this.setIndexes()
      this.updateCurrentModel(this.currentIndex)
    }
  },
  computed: {
    ...mapGetters({
      modelData: 'getModelData',
      inputHeaders: 'getInputHeaders',
      metricObject: 'getMetricObject',
      inputObject: 'getInputObject'
    }),
    landingDescription () {
      return this.narrativeLength > 0
        ? 'Create a narrative using the controls below.'
        : `No narratives found.`
    },
    narrativeID () {
      return this.narrativeTitle.trimEnd().split(' ').join('_').toLowerCase()
    },
    checkNarrativeID () {
      const response = this.$store.dispatch(
        'checkNarrativeID',
        this.narrativeID
      )
      return response
    },
    validateNarrativeID () {
      return this.narrativeTitle.length < 3 || this.narrativeID.length > 6
    },
    narrativeLength () {
      return this.exploreObject.length
    },
    currentObject () {
      return this.narrativeLength > 0
        ? this.exploreObject[this.currentIndex]
        : {}
    },
    narrativeIDErrorMessage () {
      return 'title must be at least 6 charcters long and unique'
    },
    modelObject () {
      return Object.assign({}, this.modelData[this.currentObject.modelID])
    },
    metricCard () {
      return getStarRating(this.modelObject, this.metricObject)
    },
    inputArray () {
      let inputArray = []

      for (const key in this.modelObject) {
        if (this.inputHeaders.includes(key)) {
          inputArray.push({
            label: this.inputObject[key].label,
            value: this.modelObject[key],
            key: key,
            marks: this.inputObject[key].marks
          })
        }
      }
      return inputArray
    }
  },
  methods: {
    openWindow () {
      console.log(this.narrativeURL)
      console.log(window)
      window.open(this.narrativeURL)
    },
    copyToClipboard () {
      console.log('firing')
      let copyText = document.getElementById('narrativeURL')
      copyText.focus()
      copyText.select()

      try {
        let successful = document.execCommand('copy')
        let msg = successful ? 'successful' : 'unsuccessful'
        console.log('Copying text command was ' + msg)
      } catch (err) {
        console.log('Oops, unable to copy')
      }
    },
    removeItem (index) {
      this.currentIndex = index < this.narrativeLength - 1 ? index : 0

      this.exploreObject.splice(index, 1)

      if (this.narrativeLength > 0) {
        this.setIndexes()
      }
    },
    resetNarrative () {
      this.narrativeTitle = ''
      this.narrativeDescription = ''
      this.narrativeURL = ''
    },
    async createNarrative () {
      this.narrativeIDCheck = await this.checkNarrativeID

      if (!this.narrativeIDCheck) {
        let payload = {}
        let narrative = JSON.parse(JSON.stringify(this.exploreObject))
        narrative.forEach((d) => delete d['image'])

        payload.narrativeTitle = this.narrativeTitle
        payload.narrativeID = this.narrativeID
        payload.narrativeDescription = this.narrativeDescription
        payload.narrative = narrative

        this.$store.dispatch('createNarrative', payload).then((response) => {
          const projectLocation = response.data.projectLocation
          const narrativeID = response.data.narrativeID

          this.narrativeURL = `http://localhost:8080/?project=${projectLocation}&narrativeID=${narrativeID}`
        })
      }
    },
    setIndexes () {
      this.exploreObject.forEach((d, i) => (d.index = i))
    },
    increment (increase, index) {
      let from = index
      let to = index

      if (increase && to < this.narrativeLength - 1) {
        to += 1
      } else if (!increase && to > 0) {
        to -= 1
      }

      // remove `from` item and store it
      var obj = this.exploreObject.splice(from, 1)[0]
      // splice object into position
      this.exploreObject.splice(to, 0, obj)
      this.exploreObject.forEach((d, i) => {
        d.index = i
      })
      this.currentIndex = to
    },
    updateCurrentModel (modelIndex) {
      this.newIndex = 0
      this.currentIndex = modelIndex
    }
  }
}
</script>

<style lang="scss">
#narrative {
  position: relative;

  height: calc(100vh - #{$navbar-height});
  padding: 0 2rem;

  overflow: hidden;

  input,
  textarea {
    color: $off-white;
    background: none !important;
    border-radius: 0px;
  }

  .adjust-button {
    cursor: pointer;

    &:hover {
      color: $highlight-color;
    }
  }
  .narrative-card {
    width: 60%;
  }

  .narrative-form {
    width: 400px;
  }

  .item {
    position: relative;
    border: 1px solid transparent;
    padding: 0.5rem;
    cursor: pointer;

    &.active,
    &:hover {
      border: 1px solid $blue;
    }
  }
}
</style>
